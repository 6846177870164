import { useState, useEffect, useRef } from "react";
import StaticMap, {
  Marker,
  _MapContext,
  NavigationControl, ScaleControl,
  WebMercatorViewport
} from "react-map-gl";
import config from "../../config";
import DeckGL from "@deck.gl/react";
import { BitmapLayer, GeoJsonLayer } from "@deck.gl/layers";
import GL from "@luma.gl/constants";
import "./index.css";
import bbox from '@turf/bbox'
import { useTranslation } from "react-i18next";
import moment from 'moment'


const MapComponent = ({ opacity, data, mapStyle, liveModeEnabled, isForestFireMode, colors, location }) => {
  // const deckRef = useRef(null);
  const { t } = useTranslation();
  let deckRef = useRef(null);
  const liveZoom = 4.5, nonLiveZoom = 10, vegetationZoom = 18
  const VIEW_STATE = {
    // minZoom: nonLiveZoom,
    transitionDuration: 500,
    preserveDrawingBuffer: true
  }

  const getZoomValue = () => {
    return liveModeEnabled ? liveZoom : (isForestFireMode ? nonLiveZoom : vegetationZoom)
  }

  const [viewState, setViewState] = useState({
    width: '100%',
    height: '100%',
    bearing: 0,
    pitch: 0,
    transitionDuration: 500,

    longitude: location.long,
    latitude: location.lat,
    zoom: getZoomValue(),
    minZoom: getZoomValue(),
    preserveDrawingBuffer: true,
    ...VIEW_STATE
  });

  const [tooltipObject, setTooltipObject] = useState({
    data: null
  })

  const [mapLoaded, setMapLoaded] = useState(false)

  useEffect(() => {
    if (mapLoaded) {
      let newState = {}, zoom = liveModeEnabled ? liveZoom : (isForestFireMode ? nonLiveZoom : vegetationZoom)
      if (!liveModeEnabled && ((isForestFireMode && data.data.bounds) || (!isForestFireMode && data.data))) {
        const [minLng, minLat, maxLng, maxLat] = !isForestFireMode ? bbox(data.data) : data.data.bounds
        try {
          const viewport = new WebMercatorViewport()
          const vp = viewport.fitBounds([[minLng, minLat], [maxLng, maxLat]])
          newState.latitude = vp.latitude
          newState.longitude = vp.longitude
          // newState.zoom = vp.zoom
          newState.minZoom = (isForestFireMode ? nonLiveZoom : vegetationZoom)

        } catch (e) {
          console.info(e)
        }
      }
      if (liveModeEnabled) {
        newState = {
          latitude: 46,
          longitude: 3,
          zoom: liveZoom,
          transitionDuration: 500,
          minZoom: liveZoom
        }
      }
      setViewState((prevState) => ({
        ...prevState,
        ...newState,
        zoom,
        minZoom: zoom,
        transitionDuration: 500,
        preserveDrawingBuffer: true
      }))
    }
  }, [data, isForestFireMode, liveModeEnabled, mapLoaded])

  const onMapLoad = (e) => {
    setMapLoaded(true)
  }

  const onHover = ({ x, y, object }) => {
    if (object) {
      setTooltipObject({
        x, y, data: object
      })
    }
    else {
      setTooltipObject({
        data: null
      })
    }
  }

  let layers = []

  if (isForestFireMode && data.data.bounds) {
    layers.push(
      new BitmapLayer({
        id: "bitmap-layer",
        opacity: opacity / 100,
        bounds: data.data.bounds,
        image: data.data.image,
        textureParameters: {
          [GL.TEXTURE_MIN_FILTER]: GL.NEAREST,
          [GL.TEXTURE_MAG_FILTER]: GL.NEAREST,
        },
      })
    )
  }
  else if ((!isForestFireMode && data.data) || (isForestFireMode && liveModeEnabled)) {
    layers.push(
      new GeoJsonLayer({
        id: "geoJson-layer",
        opacity: opacity / 100,
        data: data.data,
        getLineColor: e => {
          if (!liveModeEnabled) {
            return (colors && colors.length > 0 && colors[data.value - 1]) ? colors[data.value - 1] : [255, 81, 81]
          }
          else {
            return getColorForLiveLayer(e)
          }
        },
        lineWidthMinPixels: 5,
        pickable: true,
        interactive: true,
        stroked: true,
        filled: liveModeEnabled,
        getFillColor: e => {
          return getColorForLiveLayer(e)
        },
        onHover,
      }))
  }

  const getColorForLiveLayer = (obj) => {
    const year = obj.properties?.end_ts?.slice(0, 4)
    switch (year) {
      case '2023': return [255, 222, 219]
      case '2024': return [187, 78, 68]
      default: return null
    }
  }

  const renderTooltip = () => {
    return (
      <div
        className="tooltip-container"
        style={{
          left: tooltipObject.x, top: tooltipObject.y
        }}
      >
        {/* <div className="tooltip-text tooltip-title">{location.name}</div> */}
        <div className="tooltip-text"><p className="tooltip-field">{t(`dashboard.map.tooltip.startDate`)}&nbsp;:&nbsp;</p>{moment(tooltipObject.data?.properties.start_ts).format('DD/MM/YYYY')}</div>
        <div className="tooltip-text"><p className="tooltip-field">{t(`dashboard.map.tooltip.endDate`)}&nbsp;:&nbsp;</p>{moment(tooltipObject.data?.properties.end_ts).format('DD/MM/YYYY')}</div>
        <div className="tooltip-text"><p className="tooltip-field">{t(`dashboard.map.tooltip.areaBurned`)}&nbsp;:&nbsp;</p>{tooltipObject.data?.properties.burned_ha}&nbsp;ha</div>
      </div>
    )
  }
  
  return (
    <DeckGL
      ref={deckRef}
      ContextProvider={_MapContext.Provider}
      height="100%"
      width="100%"
      controller={{
        scrollZoom: true,
        dragPan: true,
        doubleClickZoom: false,
        dragRotate: false,
        keyboard: false,
      }}
      clear
      viewState={viewState}
      layers={layers}
      onViewStateChange={(vs) => {
        setViewState({
          ...vs.viewState,
          minZoom: getZoomValue(),
          preserveDrawingBuffer: true
        });
      }}
      onLoad={onMapLoad}
      className="deck-compoenent"
    >
      <StaticMap
        {...viewState}
        mapStyle={mapStyle}
        mapboxApiAccessToken={config.mapboxToken}
      ></StaticMap>
      {liveModeEnabled 
      && tooltipObject.data 
      && renderTooltip()}
      <Marker latitude={location.lat} longitude={location.long} offsetTop={-48} offsetLeft={-25}>
        <svg width="46" height="44" viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Dot">
            <circle id="Ellipse 151" cx="23" cy="22" r="5" fill="white" stroke="#94A3B8" stroke-width="15" />
            <circle class="innerCircle" cx="50%" cy="50%" r="25%" fill="white">
              <animate attributeName="r" begin="0s" dur="1s" repeatCount="indefinite" from="5px" to="10px" />
            </circle>
          </g>
        </svg>
      </Marker>

      <NavigationControl
        showCompass={false}
        className="zoom-container"
      />
      <ScaleControl
        className="scale-container"
      />
    </DeckGL>
  );
};

export default MapComponent;
