import "./index.css";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";

highchartsMore(Highcharts);
solidGauge(Highcharts);

const MeterGauge = ({ data }: any) => {
  const renderMeter = () => {

    const chartConfig = {
      chart: {
        type: 'solidgauge',
        height: '100%',
        animation: false
      },
  
      title: null,
  
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc',
          borderWidth: 0
        }
      },
  
      tooltip: {
        enabled: false
      },
      yAxis: {
        lineWidth: 0,
        minorTickInterval: null,
        tickPixelInterval: 400,
        tickWidth: 0,
        title: {
          y: -70
        },
        labels: {
          enabled: false,
          y: 16
        },
        min: data.min,
        max: data.max,
      },
  
      credits: {
        enabled: false
      },
  
      series: [{
        innerRadius: '80%',
        dataLabels: {
          enabled: false
        },
        data: [{
          color: {
            linearGradient: [0, 0, 300, 0],
            stops: [
              [0.2, '#BFE7B4'],
              [0.4, '#FFEEB2'],
              [0.6, '#FFBA9C'],
              [0.8, '#FF8787'],
              [1, '#AF4338']
            ]
          },
          y: data.value
        }],
      }]
    }
    return <HighchartsReact highcharts={Highcharts} options={chartConfig} />
      
  }

  return <>{renderMeter()}</>;
};
export default MeterGauge;
